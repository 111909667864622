import React from 'react';
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";

const valueColor = val => {
  if (val > 0) {
    return 'f-to-right f-plus';
  } else if (val < 0) {
    return 'f-to-right f-minus';
  } else {
    return 'f-to-right';
  }
};

const ReportInsuranceTableRow = ({ rows, periodName, i }) => {
  const tableRows = rows.map((row, i) => {
    return (
        <tr key={`tr_${i}`}>
          <td className='t-right-border t-left-border'>{row.insuranceCode}</td>
          <td className='t-right-border'>{row.insuranceName}</td>
          {periodName && <td className='t-right-border'>{periodName}</td>}
          <td className='f-to-right'><NumericFormat value={row.visits} thousandSeparator=" " displayType="text"/></td>
          <td className='f-to-right'><NumericFormat value={row.visitsM1Y} thousandSeparator=" " displayType="text"/></td>
          <td className={`t-right-border ${valueColor(row.visitsTrend)}`}><NumericFormat value={row.visitsTrend} thousandSeparator=" " displayType="text" decimalScale={1} decimalSeparator=","/> %</td>
          <td className='f-to-right'><NumericFormat value={row.turnover} thousandSeparator=" " displayType="text" decimalScale={0}/></td>
          <td className='f-to-right'><NumericFormat value={row.turnoverM1Y} thousandSeparator=" " displayType="text" decimalScale={0}/></td>
          <td className={`t-right-border ${valueColor(row.turnoverTrend)}`}><NumericFormat value={row.turnoverTrend} thousandSeparator=" " displayType="text" decimalScale={1} decimalSeparator=","/> %</td>
          <td className='f-to-right'><NumericFormat value={row.turnoverPerVisit} thousandSeparator=" " displayType="text" decimalScale={0}/></td>
          <td className='f-to-right'><NumericFormat value={row.turnoverM1YPerVisit} thousandSeparator=" " displayType="text" decimalScale={0}/></td>
          <td className={`t-right-border ${valueColor(row.turnoverPerVisitTrend)}`}><NumericFormat value={row.turnoverPerVisitTrend} thousandSeparator=" " displayType="text" decimalScale={1} decimalSeparator=","/> %</td>
        </tr>
    )
  })

  return tableRows;
}

const ReportInsuranceTable = ({ tableData }) => {
  const { t } = useTranslation();
  const pName = tableData.periods[0].periodName;

  const rows = tableData.periods.map((row, i) => {
    return <ReportInsuranceTableRow rows={row.insuranceCodes} periodName={row.periodName ? row.periodName : null} i={i} key={i} />
  });

  return (
      <table>
        <thead className="sticky">
        <tr>
          <th className="nowrap bg-default">Code</th>
          <th className="nowrap bg-default">Name</th>
          {pName && <th className="nowrap bg-default">Period Name</th>}
          <th className="nowrap bg-visits">{t('visitsY')}</th>
          <th className="nowrap bg-visits">{t('visitsY-1')}</th>
          <th className="nowrap bg-visits" dangerouslySetInnerHTML={{__html: t('visitsYvs')}}></th>
          <th className="nowrap bg-turnover">{t('turnoverY')}</th>
          <th className="nowrap bg-turnover">{t('turnoverY-1')}</th>
          <th className="nowrap bg-turnover" dangerouslySetInnerHTML={{__html: t('turnoverYvs')}}></th>
          <th className="nowrap bg-basket">{t('avBasketY')}</th>
          <th className="nowrap bg-basket">{t('avBasketY-1')}</th>
          <th className="nowrap bg-basket" dangerouslySetInnerHTML={{__html: t('avBasketYvs')}}></th>
        </tr>
        </thead>
        <tbody>
          {rows}
          <tr>
            <td className='t-top-border t-right-border t-left-border f-bold'>Sum</td>
            <td className='t-top-border t-right-border f-bold'></td>
            {pName && <td className='t-top-border t-right-border f-bold'>{pName}</td>}
            <td className='t-top-border f-to-right f-bold'><NumericFormat value={tableData.summary.visits} thousandSeparator=" " displayType="text"/></td>
            <td className='t-top-border f-to-right f-bold'><NumericFormat value={tableData.summary.visitsM1Y} thousandSeparator=" " displayType="text"/></td>
            <td className={`t-top-border t-right-border f-bold ${valueColor(tableData.summary.visitsTrend)}`}><NumericFormat value={tableData.summary.visitsTrend} thousandSeparator=" " displayType="text" decimalScale={1} decimalSeparator=","/> %</td>
            <td className='t-top-border f-to-right f-bold'><NumericFormat value={tableData.summary.turnover} thousandSeparator=" " displayType="text" decimalScale={0}/></td>
            <td className='t-top-border f-to-right f-bold'><NumericFormat value={tableData.summary.turnoverM1Y} thousandSeparator=" " displayType="text" decimalScale={0}/></td>
            <td className={`t-top-border t-right-border f-bold ${valueColor(tableData.summary.turnoverTrend)}`}><NumericFormat value={tableData.summary.turnoverTrend} thousandSeparator=" " displayType="text" decimalScale={1} decimalSeparator=","/> %</td>
            <td className='t-top-border f-to-right f-bold'><NumericFormat value={tableData.summary.turnoverPerVisit} thousandSeparator=" " displayType="text" decimalScale={0}/></td>
            <td className='t-top-border f-to-right f-bold'><NumericFormat value={tableData.summary.turnoverM1YPerVisit} thousandSeparator=" " displayType="text" decimalScale={0}/></td>
            <td className={`t-top-border t-right-border f-bold ${valueColor(tableData.summary.turnoverPerVisitTrend)}`}><NumericFormat value={tableData.summary.turnoverPerVisitTrend} thousandSeparator=" " displayType="text" decimalScale={1} decimalSeparator=","/> %</td>
            </tr>
        </tbody>
      </table>
  )
}

export default ReportInsuranceTable;