import React, {Fragment} from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';

const valueColor = val => {
  if (val > 0) {
    return 'f-to-right f-plus';
  } else if (val < 0) {
    return 'f-to-right f-minus';
  } else {
    return 'f-to-right';
  }
};

const ReportVisitsTurnoverVehicleTableRowOne = ({ visits, visitsM1Y, visitsTrend, turnover, turnoverM1Y, turnoverTrend, turnoverEur, turnoverEurM1Y, turnoverEurTrend, oldVehVisitsToAllVisits, eur}) => {
  const { t } = useTranslation();

  const valueColor = val => {
    if (val > 0) {
      return 'f-to-right f-plus';
    } else if (val < 0) {
      return 'f-to-right f-minus';
    } else {
      return 'f-to-right';
    }
  };


  return (
      <>
        <td className='f-to-right'><NumericFormat value={visits} thousandSeparator=" " displayType="text"/></td>
        <td className='f-to-right'><NumericFormat value={visitsM1Y} thousandSeparator=" " displayType="text"/></td>
        <td className={`t-right-border ${valueColor(visitsTrend)}`}><NumericFormat value={visitsTrend} thousandSeparator=" " displayType="text" decimalScale={1} decimalSeparator=","/> %</td>
        {!eur && <td className='f-to-right'><NumericFormat value={turnover} thousandSeparator=" " displayType="text" decimalScale={0}/></td>}
        {!eur && <td className='f-to-right'><NumericFormat value={turnoverM1Y} thousandSeparator=" " displayType="text" decimalScale={0}/></td>}
        {!eur && <td className={`t-right-border ${valueColor(turnoverTrend)}`}><NumericFormat value={turnoverTrend} thousandSeparator=" " displayType="text" decimalScale={1} decimalSeparator=","/> %</td>}
        {eur && <td className='f-to-right'><NumericFormat value={turnoverEur} thousandSeparator=" " displayType="text" decimalScale={0}/></td>}
        {eur && <td className='f-to-right'><NumericFormat value={turnoverEurM1Y} thousandSeparator=" " displayType="text" decimalScale={0}/></td>}
        {eur && <td className={`t-right-border ${valueColor(turnoverEurTrend)}`}><NumericFormat value={turnoverEurTrend} thousandSeparator=" " displayType="text" decimalScale={1} decimalSeparator=","/> %</td>}
        <td className={`f-to-right ${valueColor(oldVehVisitsToAllVisits)} t-right-border`}><NumericFormat value={oldVehVisitsToAllVisits} thousandSeparator=" " displayType="text" decimalScale={1} decimalSeparator=","/> %</td>
      </>
    )
};

const ReportVisitsTurnoverVehicleTableRow = ({ data, areaName, groupName, name, number, showPeriodName, eur, periodSelect, rowI }) => {
  const { t } = useTranslation();
  const months = t('monthsTab', { returnObjects: true });

  const rows = data.map((row, i) => {
    let p = null;
    let trClass = '';

    if (!showPeriodName && rowI === 0) trClass = 't-top-border--strong';
    if (showPeriodName && i === 0) trClass = 't-top-border--strong';

    if (row.period && row.period !== -1) {
      p = (periodSelect === "1") ? `${months[row.period - 1]} / ${row.year}` : `${row.period} / ${row.year}`;
    }

    return (
        <tr className={trClass}>
          <td className='sticky sticky--left bg-even-odd t-left-border'>{areaName}</td>
          <td className='sticky sticky--left bg-even-odd t-left-border'>{groupName}</td>
          <td className='t-left-border'>{number}</td>
          <td className={showPeriodName ? '' : 't-right-border'}>{name}</td>
          {showPeriodName && <td className='t-right-border'>{p}</td>}
          <td className='f-to-right'><NumericFormat value={row.visits} thousandSeparator=" " displayType="text"/></td>
          <td className='f-to-right'><NumericFormat value={row.visitsM1Y} thousandSeparator=" " displayType="text"/></td>
          <td className={`t-right-border ${valueColor(row.visitsTrend)}`}><NumericFormat value={row.visitsTrend} thousandSeparator=" " displayType="text" decimalScale={1} decimalSeparator=","/> %</td>
          {!eur && <td className='f-to-right'><NumericFormat value={row.turnover} thousandSeparator=" " displayType="text" decimalScale={0}/></td>}
          {!eur && <td className='f-to-right'><NumericFormat value={row.turnoverM1Y} thousandSeparator=" " displayType="text" decimalScale={0}/></td>}
          {!eur && <td className={`t-right-border ${valueColor(row.turnoverTrend)}`}><NumericFormat value={row.turnoverTrend} thousandSeparator=" " displayType="text" decimalScale={1} decimalSeparator=","/> %</td>}
          {eur && <td className='f-to-right'><NumericFormat value={row.turnoverEur} thousandSeparator=" " displayType="text" decimalScale={0}/></td>}
          {eur && <td className='f-to-right'><NumericFormat value={row.turnoverEurM1Y} thousandSeparator=" " displayType="text" decimalScale={0}/></td>}
          {eur && <td className={`t-right-border ${valueColor(row.turnoverEurTrend)}`}><NumericFormat value={row.turnoverEurTrend} thousandSeparator=" " displayType="text" decimalScale={1} decimalSeparator=","/> %</td>}
          <td className={`f-to-right ${valueColor(row.oldVehVisitsToAllVisits)} t-right-border`}><NumericFormat value={row.oldVehVisitsToAllVisits} thousandSeparator=" " displayType="text" decimalScale={1} decimalSeparator=","/> %</td>
        </tr>
    )
  });

  return rows;
};

const ReportVisitsTurnoverVehicleTable = ({ tableData, showPeriodName, eur, periodSelect, sliderVal }) => {
  const { t } = useTranslation();
  let rows = [];

  if (sliderVal === 0) {
    rows = tableData.areas.map(area => {
      return (
          <Fragment key={`t-r_${area.areaId}`}>
            <tr>
              <td className='sticky sticky--left bg-even-odd t-right-border t-left-border'>{area.areaName}</td>
              <ReportVisitsTurnoverVehicleTableRowOne visits={area.summary.visits} visitsM1Y={area.summary.visitsM1Y} visitsTrend={area.summary.visitsTrend} turnover={area.summary.turnover} turnoverM1Y={area.summary.turnoverM1Y} turnoverTrend={area.summary.turnoverTrend} turnoverEur={area.summary.turnoverEur} turnoverEurM1Y={area.summary.turnoverEurM1Y} turnoverEurTrend={area.summary.turnoverEurTrend} oldVehVisitsToAllVisits={area.summary.oldVehVisitsToAllVisits} eur={eur} />
            </tr>
          </Fragment>
      )
    });
  } else if (sliderVal === 1) {
    rows = tableData.areas.map(area => {
      let areaName = area.areaName;
      const groupsData = area.groups.map((group, i) => {
        return (
            <Fragment key={`t-r_${group.groupId}`}>
              <tr>
                <td className='sticky sticky--left bg-even-odd t-left-border'>{areaName}</td>
                <td className='sticky sticky--left bg-even-odd t-right-border t-left-border'>{group.groupName}</td>
                <ReportVisitsTurnoverVehicleTableRowOne visits={group.summary.visits} visitsM1Y={group.summary.visitsM1Y} visitsTrend={group.summary.visitsTrend} turnover={group.summary.turnover} turnoverM1Y={group.summary.turnoverM1Y} turnoverTrend={group.summary.turnoverTrend} turnoverEur={group.summary.turnoverEur} turnoverEurM1Y={group.summary.turnoverEurM1Y} turnoverEurTrend={group.summary.turnoverEurTrend} oldVehVisitsToAllVisits={group.summary.oldVehVisitsToAllVisits} eur={eur} />
              </tr>
            </Fragment>
        )
      });

      return groupsData;
    });
  } else {
    rows = tableData.areas.map(area => {
      let areaName = area.areaName;
      let periods;


        const groupsData = area.groups.map(group => {
          periods = group.dealers.map((dealer, i) => {
            return (
                <Fragment key={`t-r_${dealer.dealerNumber}`}>
                  {/*<tr className={(i === 0) ? 't-top-border--strong' : ''}>*/}
                    <ReportVisitsTurnoverVehicleTableRow data={dealer.periods} areaName={areaName} groupName={group.groupName} name={dealer.dealerName} number={dealer.dealerNumber} showPeriodName={showPeriodName} eur={eur} periodSelect={periodSelect} rowI={i} key={i} />
                  {/*</tr>*/}
                </Fragment>
            )
          });

          // if (group.dealers.length > 1) {
          //   periods.push(
          //       <Fragment key={`t-r_sum_${group.groupId}`}>
          //         <tr>
          //           <td className='sticky sticky--left bg-even-odd t-left-border'></td>
          //           <td className='sticky sticky--left bg-even-odd t-left-border'></td>
          //           <td className='sticky sticky--left bg-even-odd t-left-border'></td>
          //           <td className='sticky sticky--left bg-even-odd t-right-border t-left-border'>{t('sum')}</td>
          //           <ReportVisitsTurnoverVehicleTableRowOne visits={group.summary.visits} visitsM1Y={group.summary.visitsM1Y} visitsTrend={group.summary.visitsTrend} turnover={group.summary.turnover} turnoverM1Y={group.summary.turnoverM1Y} turnoverTrend={group.summary.turnoverTrend} turnoverEur={group.summary.turnoverEur} turnoverEurM1Y={group.summary.turnoverEurM1Y} turnoverEurTrend={group.summary.turnoverEurTrend} oldVehVisitsToAllVisits={group.summary.oldVehVisitsToAllVisits} eur={eur} />
          //         </tr>
          //       </Fragment>
          //   )
          // }

          return periods;
        });
        return groupsData;
    });
  }


  return (
      <table>
        <thead className="sticky">
          <tr>
            <th className={`sticky sticky--left sticky--top bg--gray f-bold text-center ${sliderVal === 0 ? 't-right-border' : ''}`}>{t('area')}</th>
            {sliderVal > 0 && <th className={`sticky sticky--left sticky--top bg--gray f-bold text-center ${sliderVal === 1 ? 't-right-border' : ''}`}>{t('dealerGroup')}</th>}
            {sliderVal === 2 && <th className="nowrap bg--gray">{t('dealerNumber')}</th>}
            {sliderVal === 2 && <th className="nowrap bg--gray">{t('dealerName')}</th>}
            {sliderVal === 2 && showPeriodName && <th className="nowrap bg-default">{t('period')}</th>}
            <th className="nowrap bg-visits">{t('visitsY4')}</th>
            <th className="nowrap bg-visits">{t('visitsY-14')}</th>
            <th className="nowrap bg-visits" dangerouslySetInnerHTML={{__html: t('visitsYvs4')}}></th>
            {!eur && <th className="nowrap bg-turnover">{t('turnoverY4')}</th>}
            {!eur && <th className="nowrap bg-turnover">{t('turnoverY-14')}</th>}
            {!eur && <th className="nowrap bg-turnover" dangerouslySetInnerHTML={{__html: t('turnoverYvs4')}}></th>}
            {eur && <th className="nowrap bg-turnover" dangerouslySetInnerHTML={{__html: t('turnoverYEur4')}}></th>}
            {eur && <th className="nowrap bg-turnover" dangerouslySetInnerHTML={{__html: t('turnoverY-1Eur4')}}></th>}
            {eur && <th className="nowrap bg-turnover" dangerouslySetInnerHTML={{__html: t('turnoverYvsEur4')}}></th>}
            <th className="nowrap bg-default">Veh4+ <br />to All (%)</th>
          </tr>
        </thead>
        <tbody>
          {rows}
          <tr>
            <td className={`sticky sticky--left bg-even-odd t-left-border f-bold t-top-border ${sliderVal < 1 ? 't-right-border' : ''} bg-white`}>Sum</td>
            {sliderVal > 0 && <td className={`sticky sticky--left bg-even-odd t-left-border f-bold t-top-border ${sliderVal === 1 ? 't-right-border' : ''} bg-white`}></td>}
            {sliderVal === 2 &&<td className='t-left-border f-bold t-top-border bg-white'></td>}
            {sliderVal === 2 &&<td className={showPeriodName ? 'f-bold t-top-border bg-white' : 't-right-border f-bold t-top-border bg-white'}></td>}
            {sliderVal === 2 && showPeriodName && <td className='t-right-border f-bold t-top-border bg-white'></td>}
            <td className='f-to-right f-bold t-top-border bg-white'><NumericFormat value={tableData.summary.visits} thousandSeparator=" " displayType="text"/></td>
            <td className='f-to-right f-bold t-top-border bg-white'><NumericFormat value={tableData.summary.visitsM1Y} thousandSeparator=" " displayType="text"/></td>
            <td className={`t-right-border f-bold t-top-border bg-white ${valueColor(tableData.summary.visitsTrend)}`}><NumericFormat value={tableData.summary.visitsTrend} thousandSeparator=" " displayType="text" decimalScale={1} decimalSeparator=","/> %</td>
            {!eur && <td className='f-to-right f-bold t-top-border bg-white'><NumericFormat value={tableData.summary.turnover} thousandSeparator=" " displayType="text" decimalScale={0}/></td>}
            {!eur && <td className='f-to-right f-bold t-top-border bg-white'><NumericFormat value={tableData.summary.turnoverM1Y} thousandSeparator=" " displayType="text" decimalScale={0}/></td>}
            {!eur && <td className={`t-right-border f-bold t-top-border bg-white ${valueColor(tableData.summary.turnoverTrend)}`}><NumericFormat value={tableData.summary.turnoverTrend} thousandSeparator=" " displayType="text" decimalScale={1} decimalSeparator=","/> %</td>}
            {eur && <td className='f-to-right f-bold t-top-border bg-white'><NumericFormat value={tableData.summary.turnoverEur} thousandSeparator=" " displayType="text" decimalScale={0}/></td>}
            {eur && <td className='f-to-right f-bold t-top-border bg-white'><NumericFormat value={tableData.summary.turnoverEurM1Y} thousandSeparator=" " displayType="text" decimalScale={0}/></td>}
            {eur && <td className={`t-right-border f-bold t-top-border bg-white ${valueColor(tableData.summary.turnoverEurTrend)}`}><NumericFormat value={tableData.summary.turnoverEurTrend} thousandSeparator=" " displayType="text" decimalScale={1} decimalSeparator=","/> %</td>}
            <td className={`f-to-right f-bold t-top-border ${valueColor(tableData.summary.oldVehVisitsToAllVisits)} t-right-border bg-white`}><NumericFormat value={tableData.summary.oldVehVisitsToAllVisits} thousandSeparator=" " displayType="text" decimalScale={1} decimalSeparator=","/> %</td>
          </tr>
        </tbody>
      </table>
  );
};

export default ReportVisitsTurnoverVehicleTable;
