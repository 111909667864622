import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DownloadReport from '../_shared/DownloadReport';
import ReportVisitsTurnoverDealerData from './ReportVisitsTurnoverDealerData';
import ReportFiltersBar from './ReportFiltersBar';
import SideBar from '../_shared/SideBar';
import TitleContainer from '../_shared/TitleContainer';

const ReportVisitsTurnoverDealer = () => {
  const { t } = useTranslation();
  const [filtersData, setFiltersData] = useState([]);
  const [queryFilters, setQueryFilters] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [noData, setNoData] = useState(true);

  return (
      <div className='page-wrapper'>
        <SideBar setQueryFilters={setQueryFilters} isLoading={isLoading} filtersData={filtersData} setFiltersData={setFiltersData} cumulativeField={true} cumulativeFieldValue={true} />
        <TitleContainer text={t('reportVisitsTurnoversDealerTitle')}>
          <DownloadReport disabled={noData} reportType={2} />
          <DownloadReport disabled={noData} reportType="metaReport" />
        </TitleContainer>
        <ReportFiltersBar filtersData={filtersData} queryFilters={queryFilters} />
        <ReportVisitsTurnoverDealerData queryFilters={queryFilters} isLoading={isLoading} setIsLoading={setIsLoading} noData={noData} setNoData={setNoData} />
      </div>
  );
};

export default ReportVisitsTurnoverDealer;
