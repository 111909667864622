import React, {useContext, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { Context } from '../../commons/context';
import styledSC from 'styled-components';
import CustomButtonEmpty from "./CustomButtonEmpty";
import DownloadIcon from '../_icons/Download';
import LoaderIcon from '../_icons/Loader';
import JwtInterceptor from '../_shared/JwtInterceptors';

const API_URL = process.env.REACT_APP_API_URL;

const Container = styledSC.div`
  margin-left: 10px;
`;

const DownloadReport = ({ disabled, reportType }) => {
  const { t } = useTranslation();
  const [dataContext, setDataContext] = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);

  const downloadHandler = () => {
    let queryF = dataContext.filters.queryFilters;

    setIsLoading(true);

    if (!queryF || queryF === '') {
      const now = new Date();
      const startDate = new Date(now.getFullYear(), now.getMonth() - 1, 1);
      const endDate = new Date(now.setDate(0));
      const startDateQuery = `${startDate.getFullYear()}-${startDate.getMonth()+1}-01`;
      const endDateQuery = `${endDate.getFullYear()}-${endDate.getMonth()+1}-${endDate.getDate()}`;

      queryF = `StartDate=${startDateQuery}&EndDate=${endDateQuery}&Period=week`;
    }

    JwtInterceptor
      .get(`${API_URL}Export?${queryF}&reportType=${reportType}`, {responseType: 'blob'})
      .then((result) => {
        const href = URL.createObjectURL(result.data);
        const link = document.createElement('a');

        link.href = href;
        link.setAttribute('download', 'Report-export.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);

        setIsLoading(false);
      }).catch(error => {
        console.log('Country Error', error);
        setIsLoading(false);
    });
  };

  const icon = isLoading ? <LoaderIcon/> : <DownloadIcon/>;

  return (
    <Container>
      <CustomButtonEmpty text={reportType === "metaReport" ? t('downlodMetaRaport') : t('downloadRaport')} icon={icon} onClickFnc={downloadHandler} disabled={disabled} />
    </Container>
  );
};

export default DownloadReport;
