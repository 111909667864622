import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomButtonEmpty from '../_shared/CustomButtonEmpty';
import ChartIcon from '../_icons/ChartPie';
import DownloadReport from '../_shared/DownloadReport';
import ReportVisitsTurnoverCountryData from './ReportVisitsTurnoverCountryData';
import ReportCharts from './ReportCharts';
import ReportFiltersBar from './ReportFiltersBar';
import SideBar from '../_shared/SideBar';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import TitleContainer from '../_shared/TitleContainer';

const ReportVisitsTurnoverCountry = () => {
  const { t } = useTranslation();
  const [chartDrawerOpen, setChartDrawerOpen] = useState(false);
  const [data, setData] = useState([]);
  const [filtersData, setFiltersData] = useState([]);
  const [queryFilters, setQueryFilters] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [noData, setNoData] = useState(true);

  const chartOpenDrawer = () => {
    setChartDrawerOpen(true);
  };

  const chartCloseDrawer = () => {
    setChartDrawerOpen(false);
  };

  return (
      <div className='page-wrapper'>
        <SideBar setQueryFilters={setQueryFilters} isLoading={isLoading} filtersData={filtersData} setFiltersData={setFiltersData} cumulativeField={true} cumulativeFieldValue={true} />
        <TitleContainer text={t('reportVisitsTurnoversCountryTitle')}>
          <CustomButtonEmpty text={t('charts')} icon={<ChartIcon />} onClickFnc={chartOpenDrawer} disabled={noData} />
          <DownloadReport disabled={noData} reportType={1} />
          <DownloadReport disabled={noData} reportType="metaReport" />
        </TitleContainer>
        <ReportFiltersBar filtersData={filtersData} queryFilters={queryFilters} />
        <ReportVisitsTurnoverCountryData queryFilters={queryFilters} isLoading={isLoading} setIsLoading={setIsLoading} data={data} setData={setData} noData={noData} setNoData={setNoData} />
        <SwipeableDrawer
            anchor='right'
            open={chartDrawerOpen}
            onClose={chartCloseDrawer}
            onOpen={chartOpenDrawer}
        >
          <ReportCharts data={data} closeFnc={chartCloseDrawer} />
        </SwipeableDrawer>
      </div>
  );
};

export default ReportVisitsTurnoverCountry;
