import React, {useContext, Fragment} from 'react';
import styledSC from 'styled-components';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { Context } from '../../commons/context';

const Container = styledSC.div`
  margin-top: 20px;
  
  &:first-child {
    margin-top: 0;
  }
`;

const TableContainer = styledSC.div`
  display: flex;
`;

const valueColor = val => {
  if (val > 0) {
    return 'f-to-right f-plus';
  } else if (val < 0) {
    return 'f-to-right f-minus';
  } else {
    return 'f-to-right';
  }
};

const ReportRow = ({ data }) => {
  const rows = data.map((row, i) => {
    return (
        <Fragment key={`f_${i}`}>
          <td className="f-to-right"><NumericFormat value={row.turnover} thousandSeparator=" " displayType="text" decimalSeparator="," /></td>
          <td className="f-to-right"><NumericFormat value={row.allItems} thousandSeparator=" " displayType="text"/></td>
          <td className="f-to-right"><NumericFormat value={row.partsQuantity} thousandSeparator=" " displayType="text"/></td>
          <td className="f-to-right"><NumericFormat value={row.partsQuantityM1Y} thousandSeparator=" " displayType="text"/></td>
          <td className={valueColor(row.partsQuantityTrend)}><NumericFormat value={row.partsQuantityTrend} thousandSeparator=" " displayType="text" decimalScale={1} decimalSeparator="," /> %</td>
          <td className="f-to-right"><NumericFormat value={row.partsQuantityToAllItems} thousandSeparator=" " displayType="text" decimalScale={1} decimalSeparator="," /> %</td>
          <td className="f-to-right"><NumericFormat value={row.partsShareVsCountry} thousandSeparator=" " displayType="text" decimalScale={1} decimalSeparator="," /> %</td>
          <td className="f-to-right"><NumericFormat value={row.partsShareVsArea} thousandSeparator=" " displayType="text" decimalScale={1} decimalSeparator="," /> %</td>
          <td className={`${valueColor(row.partsShareToCountryAvg)} f-to-right t-right-border`}><NumericFormat value={row.partsShareToCountryAvg} thousandSeparator=" " displayType="text" decimalScale={1} decimalSeparator="," /> %</td>
        </Fragment>
    );
  });

  return rows;
};

const ReportSumRow = ({ data }) => {
  const rows = data.map((row, i) => {
    return (
        <Fragment key={`f_${i}`}>
          <td className="f-to-right f-bold t-top-border"><NumericFormat value={row.turnover} thousandSeparator=" " displayType="text" decimalSeparator="," /></td>
          <td className="f-to-right f-bold t-top-border"><NumericFormat value={row.allItems} thousandSeparator=" " displayType="text"/></td>
          <td className="f-to-right f-bold t-top-border"><NumericFormat value={row.partsQuantity} thousandSeparator=" " displayType="text"/></td>
          <td className="f-to-right f-bold t-top-border"><NumericFormat value={row.partsQuantityM1Y} thousandSeparator=" " displayType="text"/></td>
          <td className={`${valueColor(row.partsQuantityTrend)} f-bold t-top-border`}><NumericFormat value={row.partsQuantityTrend} thousandSeparator=" " displayType="text" decimalScale={1} decimalSeparator="," /> %</td>
          <td className="f-to-right f-bold t-top-border"><NumericFormat value={row.partsQuantityToAllItems} thousandSeparator=" " displayType="text" decimalScale={1} decimalSeparator="," /> %</td>
          <td className="f-to-right f-bold t-top-border"><NumericFormat value={row.partsShareVsCountry} thousandSeparator=" " displayType="text" decimalScale={1} decimalSeparator="," /> %</td>
          <td className="f-to-right f-bold t-top-border"><NumericFormat value={row.partsShareVsArea} thousandSeparator=" " displayType="text" decimalScale={1} decimalSeparator="," /> %</td>
          <td className={`${valueColor(row.partsShareToCountryAvg)} f-to-right t-right-border f-bold t-top-border`}><NumericFormat value={row.partsShareToCountryAvg} thousandSeparator=" " displayType="text" decimalScale={1} decimalSeparator="," /> %</td>
        </Fragment>
    );
  });

  return rows;
};

const ReportTop20Table = ({ tableData, sliderVal }) => {
  const { t } = useTranslation();
  const [dataContext] = useContext(Context);
  const categoriesNames = tableData.areas[0].groups[0].dealers[0].periods[0].categories;
  const categoriesLength = tableData.areas[0].groups[0].dealers[0].periods[0].categories.length;
  const categoriesTitle = [];
  const columnsTitle = [];
  const headerFamilies = [];
  let rows = [];
  let sum = [];

  const families = dataContext.filters.categoriesData.filter(d => d.itemFamilies.length === 0 && d.itemCodes.length > 0).map(c => c.id);

  if (sliderVal === 0) {
    rows = tableData.areas.map(area => {
      return (
          <Fragment key={`t-r_${area.areaId}`}>
            <tr>
              <td className='sticky sticky--left bg-even-odd t-right-border t-left-border'>{area.areaName}</td>
              <ReportRow data={area.summary.categories} />
            </tr>
          </Fragment>
      )
    });
  } else if (sliderVal === 1) {
    rows = tableData.areas.map(area => {
      let areaName = area.areaName;
      const groupsData = area.groups.map((group, i) => {
          return (
              <Fragment key={`t-r_${group.groupId}`}>
                <tr>
                  <td className='sticky sticky--left bg-even-odd t-left-border'>{areaName}</td>
                  <td className='sticky sticky--left bg-even-odd t-right-border t-left-border'>{group.groupName}</td>
                  <ReportRow data={group.summary.categories} />
                </tr>
              </Fragment>
          )
        });

      return groupsData;
    });
  } else {
    rows = tableData.areas.map(area => {
      let areaName = area.areaName;
      let periods;
      const groupsData = area.groups.map(group => {
        let dealerGroup = group.groupName;
        periods = group.dealers.map((dealer, i) => {
          if (i === 0) {
            for (let j = 0; j <= categoriesLength - 1; j++) {
              if (families.includes(dealer.periods[0].categories[j].categoryId)) headerFamilies.push(j);
            }
          }
          return (
              <Fragment key={`t-r_${dealer.dealerNumber}`}>
                <tr className={(i === 0) ? 't-top-border--strong' : ''}>
                  <td className='sticky sticky--left bg-even-odd t-left-border'>{areaName}</td>
                  <td className='sticky sticky--left bg-even-odd t-left-border'>{dealerGroup}</td>
                  <td className='sticky sticky--left bg-even-odd t-left-border'>{dealer.dealerNumber}</td>
                  <td className='sticky sticky--left bg-even-odd t-right-border t-left-border'>{dealer.dealerName}</td>
                  <ReportRow data={dealer.periods[0].categories} />
                </tr>
              </Fragment>
          )
        });

        if (group.dealers.length > 1) {
          periods.push(
              <Fragment key={`t-r_sum_${group.groupId}`}>
                <tr>
                  <td className='sticky sticky--left bg-even-odd t-left-border'></td>
                  <td className='sticky sticky--left bg-even-odd t-left-border'></td>
                  <td className='sticky sticky--left bg-even-odd t-left-border'></td>
                  <td className='sticky sticky--left bg-even-odd t-right-border t-left-border'>{t('sum')}</td>
                  <ReportRow data={group.summary.categories} />
                </tr>
              </Fragment>
          )
        }

        return periods;
      });
      return groupsData;
    });
  }

  for (let i = 0; i <= categoriesLength-1; i++) {
    categoriesTitle.push(<Fragment key={`for_c_${i}`}><th className="bg--gray nowrap" colSpan="9">{categoriesNames[i].categoryName}</th></Fragment>);
  }

  for (let i = 0; i <= categoriesLength-1; i++) {
    columnsTitle.push(<Fragment key={`for_th_${i}`}><th className="nowrap bg--gray">{t('top20turnover')}</th><th className="nowrap bg--gray">{t('top20visits')}</th><th className="nowrap bg--gray">{t('top20Y')}</th><th className="nowrap bg--gray">{t('top20Y-1')}</th><th className="nowrap bg--gray">{t('top20YvsY-1')}</th><th className="nowrap bg--gray">{(headerFamilies.includes(i)) ? t('top20participationFamily') : t('top20participation')}</th><th className="nowrap bg--gray">{t('avgForCountry')}</th><th className="nowrap bg--gray">{t('avgForArea')}</th><th className="nowrap bg--gray">{t('top20vs')}</th></Fragment>);
  }

  return (
      <Container>
        <TableContainer>
          <table>
            <thead className="sticky">
              <tr>
                <td className={`sticky sticky--left sticky--top bg--gray f-grey ${sliderVal === 0 ? 't-right-border' : ''}`}>a</td>
                {sliderVal > 0 && <td className={`sticky sticky--left sticky--top bg--gray  f-grey ${sliderVal === 1 ? 't-right-border' : ''}`}>a</td>}
                {sliderVal === 2 && <td className="sticky sticky--left sticky--top bg--gray f-grey">a</td>}
                {sliderVal === 2 && <td className="sticky sticky--left sticky--top bg--gray t-right-border f-grey">a</td>}
                {categoriesTitle}
              </tr>
              <tr>
                <th className={`sticky sticky--left sticky--top bg--gray f-bold text-center ${sliderVal === 0 ? 't-right-border' : ''}`}>{t('area')}</th>
                {sliderVal > 0 && <th className={`sticky sticky--left sticky--top bg--gray f-bold text-center ${sliderVal === 1 ? 't-right-border' : ''}`}>{t('dealerGroup')}</th>}
                {sliderVal === 2 && <th className='sticky sticky--left sticky--top bg--gray f-bold text-center'>{t('dealerNumber')}</th>}
                {sliderVal === 2 && <td className="sticky sticky--left sticky--top bg--gray t-right-border f-bold text-center">{t('dealerName')}</td>}
                {columnsTitle}
              </tr>
            </thead>
            <tbody>
              {rows}
              <tr>
                <td className={`z2 f-to-right f-bold t-top-border t-left-border sticky sticky--left sticky--top ${sliderVal < 1 ? 't-right-border' : ''}`}>Sum</td>
                {sliderVal > 0 && <th className={`sticky sticky--left sticky--top bg--odd t-top-border f-bold text-center ${sliderVal === 1 ? 't-right-border' : ''}`}></th>}
                {sliderVal === 2 && <th className='sticky sticky--left sticky--top t-top-border bg--odd f-bold text-center'></th>}
                {sliderVal === 2 && <td className="sticky sticky--left sticky--top t-top-border bg--odd t-right-border f-bold text-center"></td>}
                <ReportSumRow data={tableData.summary.categories} />
              </tr>
            </tbody>
          </table>

        </TableContainer>
      </Container>
  );
};

export default ReportTop20Table;
